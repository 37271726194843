body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #212121;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.niu {
  position: absolute;
  top: 5%;
  right: -8%;
  z-index: -1;
}

.home_btn {
  margin-top: 33px !important;
  width: 500px;
  height: 55px;
  background: #34AADC !important;
  border-radius: 100px !important;
  font-size: 18px;
  color: #FAFAFA !important;
  letter-spacing: 0.85px;
  outline: none !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  position: relative;
}

.screen_audio {
  color: #FAFAFA;
}
.style_input__2hCkD {
  width: 500px;
  height: 55px;
  background: #49494B;
  border-radius: 51px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 35px;
  z-index: 10;
  position: relative;
}

.style_input__2hCkD input, select {
  opacity: 0.86;
  font-family: PingFangSC-Light;
  font-size: 14px;
  color: #FAFAFA;
  height: 100%;
  width: 100%;
  letter-spacing: 0.66px;
  text-align: left;
  background: none;
  outline: none;
  border: none;
}
.hint {
  opacity: 0.68;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0.62px;
  text-align: left;
  width: 100%;
  padding-left: 35px;
  margin-bottom: 5px;
}

.home_user {
  margin-bottom: 38px;
  opacity: 0.61;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0.41px;
  text-align: center;
}

.style_input__1t42V {
  width: 500px;
  height: 55px;
  background: #49494B;
  border-radius: 51px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 35px;
  z-index: 10;
  position: relative;
}

.style_input__1t42V select {
  opacity: 0.86;
  font-family: PingFangSC-Light;
  font-size: 14px;
  color: #39b54a;
  height: 100%;
  width: 100%;
  letter-spacing: 0.66px;
  text-align: left;
  background: none;
  outline: none;
  border: none;
}

.UserPlayer_root__2iE5I {
  position: relative;
  background: #000;
  width: 200px;
  height: 150px;
}
.UserPlayer_zoom__39Ek9 {
  width: 200px;
  height: 150px;
}
.UserPlayer_screen__2J-2r {
  width: 100%;
  height: 100%;
}

.UserPlayer_root__2iE5I>div {
  width: 100%;
  height: 100%;
  z-index: 0;
}

.UserPlayer_root__2iE5I>div:nth-child(1) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.UserPlayer_root__2iE5I>div:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 40%;
}

.UserPlayer_root__2iE5I>div:nth-child(3) {
  display: none;
}

.UserPlayer_userName__2ocrW {
  left: 0;
  width: 100%;
  position: absolute;
  text-align: center;
}

.SwitchPanel_switchPanel__2x7r8 {
  background: rgba(28,28,28,0.8);
  border-radius: 4px;
  color: #fff;
  left: 10px;
  position: absolute;
  top: 200px;
  z-index: 64;
  min-width: 22em;
  font-size: 11px;
  padding: 5px;
}
.SwitchPanel_formControl__2l4g9 {
  display: block;
}
.SwitchPanel_formControl__2l4g9 > .SwitchPanel_select__LUeLL {
  min-width: 120px;
}

